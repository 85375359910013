<template>
  <div>
    <a-page-header
      title="协议签署记录查看"
      class="hearStyle"
    />
    <div>
      <a-form :form="form" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }" @submit="handleSubmit">
          <a-form-item label="协议ID">
            {{ info.agreementId }}
          </a-form-item>
          <a-form-item label="协议名称">
            {{ info.agreementName }}
          </a-form-item>
          <a-form-item label="协议类型">
            {{ info.agreementType }}
          </a-form-item>
          <a-form-item label="适用项目">
            {{ info.projectIds }}
          </a-form-item>
          <a-form-item label="用户名">
            {{ info.userRealName }}
          </a-form-item>
          <a-form-item label="用户手机号">
            {{ info.userPhone }}
          </a-form-item>
          <a-form-item label="签署时间">
            {{ moment(info.gmtSigned).format('YYYY-MM-DD HH:mm:ss')}}
          </a-form-item>
          <a-form-item label="签署项目">
            {{ info.projectId }}
          </a-form-item>
          <a-form-item label="签署状态">
            {{ info.status ? '已签署' : '已取消' }}
          </a-form-item>
          <a-form-item label="协议内容">
            <div class="agreementContent">
              <div v-html="info.agreementContent"></div>
            </div>
          </a-form-item>
          <a-form-item v-bind="tailFormItemLayout">
            <a-button type="primary" @click="goBack">
              返回
            </a-button>
          </a-form-item>
      </a-form>

    </div>
  </div>
</template>
<script>
import { getAgreementSigningRecordDetail } from "../../../../api/agreement";
import moment from "moment";
export default {
  data() {
    return {
      info: {},
      moment,
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
    }
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
  methods: {
    async getDetail(id) {
      const res = await getAgreementSigningRecordDetail(id)
      const { code, data } = res
      if (code === '200') {
        data.agreementType = data.agreementType === "1" ? '通勤巴士用户协议'
          : data.agreementType === '2' ? "积分支付用户协议" : 'App用户协议'
        this.info = data
      }
    },

    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.agreementContent {
  padding: 20px;
  height: 500px;
  width: 100%;
  overflow: auto;
}
.ql-size-small{
  font-size: 0.75em !important;
}
strong{
  font-weight:600 !important;
}
em{
  font-style: oblique !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}
.ql-align-center strong{
  font-weight:600
}
.ql-editor p{
  color: #2c3e50;
  line-height: 0.64rem;
  font-size: 0.32rem;
}
.ql-editor table {
  border: #000000 solid #000000;
}
</style>
